<template>
  <div class="default-wrap">
    <product-detail v-if="route.fullPath.includes('detail')"></product-detail>
    <product-list v-else></product-list>
  </div>
</template>
<script>
import { defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useMutations, useActions } from '@/utils/mapStore';
export default {
  name: 'set-default',
  components: {
    ProductList: defineAsyncComponent(() => import('@/components/default/ProductList.vue')),
    ProductDetail: defineAsyncComponent(() => import('@/components/default/ProductDetail.vue')),
  },
  setup() {
    const route = useRoute();
    //取得商品類別表
    const doGetProductCategory = useActions('default', ['doGetProductCategory']);
    doGetProductCategory();
    //離開清空vuex
    const RESET = useMutations('default', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/product/default')) RESET();
      }
    );
    return { route };
  },
};
</script>
<style lang="scss" scoped>
.default-wrap {
  width: 100%;
  height: 100%;
}
</style>
